<template>
	<div>
		<div class="border border-solid border-blue-200">
			<div class="flex flex-col">
				<div class="duration-300">
					<datatable
						:data="cashbacks.data"
						:columns="columns"
						:fillable="false"
						:loading="cashbacks.loading"
						ref="table"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		staff: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			cashbacks: this.$options.resource([]),
			columns: [
				{
					name: "reference",
					th: "Reference",
				},
				{
					name: "commission",
					th: "Commission",
					render: (cashback) =>
						cashback?.type === "percentage"
							? `${cashback?.commission}%`
							: cashback?.commission,
				},
				{
					name: "amount",
					th: "Amount",
					render: (cashback) =>
						this.$options.filters.formatAmount(cashback?.amount),
				},
				{
					name: "cashback",
					th: "Cashback",
					render: (cashback) =>
						this.$options.filters.formatAmount(cashback?.cashback),
				},
				{
					name: "merchant",
					th: "Merchant",
					render: (cashback) => cashback?.merchant?.business_name,
				},
				{
					name: "created_at",
					th: "Created At",
					render: (cashback) =>
						this.$moment(cashback?.created_at).format("dddd, MMMM Do YYYY"),
				},
			],
		};
	},
	beforeMount() {
		this.userCashbacks();
	},
	methods: {
		async userCashbacks() {
			this.cashbacks.loading = true
			await this.sendRequest("admin.wallets.userCashback", this.staff?.id, {
				success: ({data : { data }}) => this.cashbacks.data = data,
				error: (error) => console.log(error),
			});
			this.cashbacks.loading = false
		},
	},
};
</script>